<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Vue <br>Analytics
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <br><br><br>
      <div class="w-100 text-center mt-4">
        <h2 class="mb-1">
          Website Suspended
        </h2>
        <p class="mb-2">
          Oops! 😖 The requested website / webapp has been suspended. Are you
          the owner? Contact Vue Analytics to reactivate.
        </p>

        <div class="row justify-content-center pb-2">
          <div class="py-2 px-2">
            <b-media>
              <template>
                <b-avatar :src="require('@/assets/images/avatars/ian.jpg')" />
              </template>
              <h6>Ian</h6>
              <span class="text-muted">Head of Networking</span>
            </b-media>
            <div class="pt-1">
              <a href="mailto:ian@vue.co.ke?subject=VUE%20HOSTING:%20Site%20Suspended&body=My%20site%20has%20been%20suspended&cc=support@vue.co.ke">
                <feather-icon icon="MessageSquareIcon" class="mr-1" />
              </a>
              <a href="tel:+254710710037">
                <feather-icon icon="PhoneCallIcon" />
              </a>
            </div>
          </div>
        </div>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          href="https://vue.co.ke"
        >
          Back to Vue
        </b-button>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BButton, BImg, BAvatar, BMedia,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    BAvatar,
    BMedia,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
